.our-projects{
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.our-projects>.overlay{
    inset: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #171717ad;
}

.our-projects .header{
    padding-top: 120px;
    padding-bottom: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}


.our-projects .header .text h2{
    font-size: 15px;
    color: var(--sec-color);
}

.our-projects .header .text h1{
    color: #000;
    font-size: 27px;
    font-weight: 900;
    width: 90%;
    margin-bottom: 44px;
    text-align: left;
}

.our-projects .header .text h1 span{
    color: var(--sec-color);

}

.our-projects .header a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 48px;
    background-color: var(--sec-color);
    color: #fff;
}

.our-projects .project{
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 25px;
    margin-bottom: 50px;
    background-color: #fff;
    min-height: 200px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.22);
    padding: 20px;
}


.our-projects .project img{
    width: 30%;
    object-fit: cover;
    height: 250px;
}

.our-projects .project .text{
    width: 70%;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 250px;
}

.our-projects .project .text div{
    color: #000;
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.our-projects .project .text div span i{
    color: var(--sec-color);
}

.our-projects .project .text h1{
    font-size: 23px;
    font-weight: 600;
    color: #000;
    padding-bottom: 22px;
    border-bottom: 1px solid var(--sec-color);
}

.our-projects .project .text a{
    width: 202px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    outline: none;
    border: 1px solid var(--sec-color);
    color: #000;
    font-size: 16px;
}


.our-projects .project.special {
    flex-direction: column;
    min-height: 629px;
}


.our-projects .project.special img{
    width: 100%;
}

.our-projects .project.special .text{
    width: 100%;
}




.our-projects .lets-talk{
    position: relative;
    z-index: 2;
    min-height: 550px;
    padding: 20px;
    margin-bottom: 100px;
}

.our-projects .lets-talk img{
    width: 100%;
    height: 100%;
    inset: 0;
    position: absolute;
    object-fit: cover;
}

.our-projects .lets-talk .overlay{
    background-color: #171717a1;
    inset: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.our-projects .lets-talk .text{
    position: relative;
    z-index: 3;
    min-height: 540px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.our-projects .lets-talk .text h2{
    font-size: 18px;
    color: #fff;
}

.our-projects .lets-talk .text h1{
    width: 67%;
    font-size: 32px;
    font-weight: 700;
    margin-top: 30px;
    line-height: 50px;
    color: #fff;
}

.our-projects .lets-talk .text a{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #fffffff5;
    color: #fff;
    font-size: 19px;
}


@media(max-width:950px){
    .our-projects .header {
        padding-top: 120px;
        padding-bottom: 75px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        z-index: 1;
    }

    .our-projects .project {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        margin-bottom: 50px;
        background-color: #fff;
        min-height: 200px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.22);
        padding: 20px;
    }

    .our-projects .project .text {
        width: 100%;
        padding: 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 250px;
    }

    .our-projects .project img {
        width: 100%;
        object-fit: cover;
        height: 250px;
    }
}