footer{
    position: relative;
    color: #000;
    box-shadow:0px 0px 15px rgba(0, 0, 0, 0.22);
}

footer>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
}

footer>.overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
}


footer .footer-content{
    position: relative;
    z-index: 3;
    color: #fff;
    min-height: 300px;
    padding: 18px;
    padding-top: 92px;
    padding-bottom: 42px;
    min-height: 494px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

footer .footer-content.border-right{
    border-right: 1px solid #ffffff0d;
}

footer .footer-content h1{
    color: #000;
    font-size: 27px;
    font-weight: 900;
    width: 90%;
    margin-bottom: 44px;
    text-align: left;
}

footer .footer-content .links{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 21px;
}

footer .footer-content .links a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    color: #fff;
    font-size: 14px;
}

footer .footer-content .contact-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 62px;
}

footer .footer-content .contact-info>div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 200px;
}

footer .footer-content .contact-info>div h1{
    font-size: 17px;
    font-weight: 700;
}

footer .footer-content .contact-info>div>a{
    color: #fff;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    justify-content: center;
    gap: 11px;
    margin-bottom: 23px;
    color: #565656;
}

footer .footer-content .contact-info>div ul{
    padding: 0;
    display: flex;
    gap: 21px;
}

footer .footer-content .contact-info>div ul a{
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #00a5da;
    color: #fff;
    font-size: 18px;
    border-radius: 50%;
}


footer .copy-right {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    border-top: 2px solid #00a5da;
    background-color: #00a5da;
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

footer .copy-right ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    color: #fff;
    font-size: 14px;
}

footer .logo {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

footer .copy-right ul a{
    display: flex;
    color: #000;
}

footer .copy-right h3 {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
}

footer .copy-right h3 a{
    color: #fff;
}

footer .footer-content .links a:nth-child(1){
    background-color: #00a5da;
}

footer .footer-content .links a:nth-child(2){
    background-color: #fff;
    color: #000;
    box-shadow:0px 0px 15px rgba(0, 0, 0, 0.22);

}


footer .logo img {
    padding: 15px;
    width: 191px;
    height: 90px;
    object-fit: contain;
    background-color: #fff;
}


.footer-logo{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


footer .footer-links {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0 !important;
    padding: 0;
    padding-left: 30px;
    padding-right: 30px;
}




    footer .footer-links li a {
        color: #000;
        width: 206px;
        height: 45px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        box-shadow: 0px -3px 4px rgb(0 0 0 / 7%);
        font-size: 15px;
        padding: 19px;
    }
    

    @media(max-width:950px){
        footer .footer-links {
            z-index: 2;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0 !important;
            padding: 0;
            padding-left: 30px;
            padding-right: 30px;
            gap: 30px;
        }

        footer .footer-content .contact-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 62px;
        }


        footer .footer-content {
            position: relative;
            z-index: 3;
            color: #fff;
            min-height: 300px;
            padding: 18px;
            padding-top: 92px;
            padding-bottom: 42px;
            min-height: 255px;
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
    }