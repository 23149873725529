.about{
    margin-top: 83px;
}


.about .about-img{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.about .about-img img{
    width: 73%;
    height: 700px;
    object-fit: cover;
}

.about .about-img .overlay{
    width: 76%;
    height: 100%;
    z-index: -1;
    position: absolute;
    inset: 0;
    border: 7px solid var(--sec-color);
    top: 28px;
    left: 116px;
}


.about .about-img .years{
    width: 199px;
    min-height: 253px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    padding: 15px;
    position: absolute;
    right: 0;
    background-color: #fff;
    border: 1px solid #d3d3d3;
}

.about .about-img .years>div{
    position: relative;
    width: 100%;
}

.about .about-img .years>div .sm-overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: var(--sec-color);
    opacity: 0.2;
}



.about .about-img .years>div span{
    position: absolute;
    z-index: 2;
    color: #fff;
    font-size: 46px;
    font-weight: 500;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}



.about .about-img .years>div img{
    width: 100%;
    height: 173px;
    object-fit: cover;
}

.about .about-img .years h2{
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    width: 80%;
}

.about .about-text h2{
    font-size: 15px;
    color: var(--sec-color);
}

.about .about-text h1 {
    color: #000;
    font-size: 27px;
    font-weight: 900;
    width: 90%;
    margin-bottom: 44px;
}

.about .about-text h1 span{
    color: var(--sec-color);

}

.about .about-text p{
    font-size: 16px;
    width: 95%;
    color: #000000;
}


.about .about-text ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 11px;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
}

.about .about-text ul li {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #6f6f6f;
    width: 76%;
    margin-bottom: 22px;
}

.about .about-text ul li i{
    color: var(--sec-color);
}


.about .about-text .mission{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 19px;
    margin-bottom: 39px;
}

.about .about-text .mission img{
    width: 195px;
    height: 142px;
    object-fit: cover;
}

.about .about-text .mission div{
    width: 100%;
    height: 151px;
}

.about .about-text .mission div h1{
    font-size: 24px;
    margin-bottom: 24px;
}

.about .about-text .mission div p{
    font-size: 15px;
    width: 95%;
    color: #5d5d5d;
}

.about .about-text .apoint{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 51px;
}

.about .about-text .apoint>a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 48px;
    background-color: var(--sec-color);
    color: #fff;
}


.about .about-text .apoint .about-contact{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.about .about-text .apoint .about-contact>a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: var(--sec-color);
    color: #fff;
    font-size: 22px;
    border-radius: 50%;
}


.about .about-text .apoint .about-contact>div{
    margin-left: 22px;
}


.about .about-text .apoint .about-contact>div h3{
    font-size: 16px;
    color: #848484;
    font-weight: 400;
}


.about .about-text .apoint .about-contact>div a{
    font-size: 18px;
    color: #000;
    width: 100%;
    display: flex;
}


@media(max-width:950px){
    .about .about-img img {
        width: 73%;
        height: 388px;
        object-fit: cover;
    }

    .about .about-img .overlay {
        width: 76%;
        height: 100%;
        z-index: -1;
        position: absolute;
        inset: 0;
        border: 7px solid var(--sec-color);
        top: 28px;
        left: 75px;
    }

    .about .about-text h2 {
        font-size: 15px;
        color: var(--sec-color);
        margin-top: 93px;
    }

    .about .about-text h1 {
        color: #000;
        font-size: 26px;
        font-weight: 600;
        width: 100%;
        margin-bottom: 44px;
    }

    .about .about-text p {
        font-size: 19px;
        width: 95%;
        color: #363636;
        margin-bottom: 57px;
    }


    .about .about-text ul li {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 5px;
        font-size: 17px;
        font-weight: 600;
        color: #5e5e5e;
        width: 96%;
    }

    .about .about-text .mission {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 19px;
        margin-bottom: 39px;
        margin-top: 66px;
    }

    .about .about-text .mission img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .about .about-text .mission div p {
        font-size: 17px;
        width: 95%;
        color: #525252;
        margin-bottom: 61px;
    }

    .about .about-text .apoint {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        justify-content: flex-start;
        gap: 51px;
        margin-top: 101px;
    }
}