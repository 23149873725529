


nav {
    background-color: #f8f8f8;
    z-index: 15;
    position: relative;
    border-bottom: 8px solid #00a5da;
}
nav .search button {
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 25px;
    color: #000;
}

nav .nav-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

nav .nav-content .logo {
    width: 15%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

nav .nav-content .logo img {
    width: 125px;
}


nav .nav-content .nav-inner-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

nav .nav-content .nav-inner-content .top{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 53px;
    align-items: center;
    padding-left: 25px;
}

nav .nav-content .nav-inner-content .top a {
    color: #000;
    font-size: 15px;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-weight: 400;
}

nav .nav-content .nav-inner-content .top a i{
    color: var(--sec-color);
}



nav .nav-content .nav-inner-content .bottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 55px;
    align-items: center;
    padding-left: 25px;
}


nav .nav-content .nav-inner-content .bottom .nav-elements ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 49px;
}



nav .nav-content .nav-inner-content .bottom .nav-elements ul li a {
    color: #5d5d5d;
    font-size: 15px;
    transition: 0.3s;
    font-weight: 700;
}

nav .nav-content .nav-inner-content .bottom .nav-elements ul li a:hover{
    color: var(--sec-color);
}

nav .nav-content .nav-inner-content .bottom .nav-contact{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 29px;
}

nav .nav-content .nav-inner-content .bottom .nav-contact a:nth-child(1){
    color: #000;
    font-size: 21px;
}

nav .nav-content .nav-inner-content .bottom .nav-contact a:nth-child(2){
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: #000;
}


.search-content{
    position: absolute;
    left: 50%;
    right: 0;
    min-height: 60px;
    z-index: 18;
    width: 100%;
    background-color: #0c356a;
    margin-top: 10px;
    width: 98%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    top: -100%;
    transition: 0.5s;
    opacity: 0;
    user-select: none;
    pointer-events: none;
}

.search-content.active {
    top: 12%;
    opacity: 1;
    user-select: inherit;
    pointer-events: inherit;
}


.search-content input{
    width: 100%;
    height: 46px;
    padding-left: 12px;
    color: #000;
    font-size: 16px;
    outline: none;
    border: none;
    font-weight: 400;
}


.search-content .products{
    width: 100%;
}

.search-content .products .product{
    width: 100%;
    margin-top: 26px;
    background-color: #ffffff12;
    padding: 9px;
}


.search-content .products a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
}

.search-content .products a img{
    width: 65px;
    height: 65px;
    object-fit: contain;
}


.search-content .products a h1{ 
    font-size: 15px;
    color: #fff;
}



.our-solutions-pages{
    position: absolute;
    left: 50%;
    right: 0;
    min-height: 60px;
    z-index: 18;
    width: 100%;
    background-color: #0c356a;
    margin-top: 10px;
    width: 98%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    top: -115%;
    transition: 0.5s;
}

.our-solutions-pages.active {
    top: 12%;
}


.our-solutions-pages .content{
    width: 100%;

}

.our-solutions-pages .content .main-menu{
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 512px;
}




.our-solutions-pages .content .main-menu>li{
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    transition: 0.5s;
}

.our-solutions-pages .content .main-menu>li:hover{
    background-color: #09264c;

}



.our-solutions-pages .content .main-menu>li>a{
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}



.our-solutions-pages .content .main-menu>li>a i{
    color: var(--sec-color);;
}

.our-solutions-pages .content .main-menu>li .sub-menu{
    display: block;
    height: 95%;
    padding: 0;
    top: 3%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #061d3b;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 29px;
    color: #fff;
    width: 53%;
    transition: 0.5s;
    opacity: 0;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    pointer-events: none;
}

.our-solutions-pages .content .main-menu>li:hover .sub-menu{
    opacity: 1;
    user-select: inherit;
    pointer-events: inherit;
}


.our-solutions-pages .content .main-menu>li .sub-menu li a{
    color: #a9a9a9;
    transition: 0.5s;
}

.our-solutions-pages .content .main-menu>li .sub-menu li a:hover{
    color: #fff;
}





#open-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
    color: #000;
}

#close-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}



@media(max-width:950px){

    nav .nav-content .nav-inner-content .bottom .nav-contact {
        display: none;
    }
    
    #open-nav{
        display: block;
    }

    #close-nav{
        display: block;
    }
    
    nav .nav-content .logo {
        width: 25%;
        height: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    nav .nav-content .nav-inner-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 55%;
    }

    nav .nav-content .nav-inner-content .bottom .nav-elements ul {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 49px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 520px;
    }
    

    
    #close-nav {
        display: block;
        font-size: 36px;
        margin-top: 48px;
    }
    nav .nav-content .nav-inner-content .bottom .nav-elements ul li a {
        color: #5d5d5d;
        font-size: 17px;
        transition: 0.3s;
        font-weight: 700;
    }

    
    .nav-elements {
        display: flex;
        flex-direction: column;
        position: fixed;
        left: 0;
        right: 0;
        background-color: #fff;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
        top: -1200px;
        transition: 0.5s;
    }


    .nav-elements.active {
        top: 0;
    }





}

