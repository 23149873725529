.contact-us{
    margin-top: 90px;
}

.contact-us .contact-info img{
    width: 100%;
    height: 250px;
    object-fit: cover;
}


.contact-us .contact-info .info{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #f9f9f9;
    box-shadow:0px 0px 6px rgba(0, 0, 0, 0.15);
}

.contact-us .contact-info .info i{
    font-size: 56px;
    color: #000;
}


.contact-us .contact-info .info div h2{
    font-size: 18px;
    color: #000;
}

.contact-us .contact-info .info div a {
    font-size: 16px;
    color: #000;
}
.contact-us .form-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #f9f9f9;
    margin-top: 72px;
    box-shadow:0px 0px 6px rgba(0, 0, 0, 0.15);
}

.contact-us .form-content form{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 50px;
}

.contact-us .form-content form .inputs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.contact-us .form-content form .inputs .input{
    width: 100%;
}

.contact-us .form-content form .inputs .input input{
    width: 100%;
    height: 56px;
    border: 1px solid #fff;
    color: #000;
    background-color: #ffff;
    outline: none;
    padding-left: 18px;
    box-shadow:0px 0px 6px rgba(0, 0, 0, 0.15);
}

.contact-us .form-content form .inputs .input input[type="file"]{
    padding: 15px;

}


.contact-us .form-content form .inputs .input textarea{
    width: 100%;
    height: 200px;
    border: 1px solid #fff;
    color: #000;
    background-color: #ffff;
    outline: none;
    padding-left: 18px;
    box-shadow:0px 0px 6px rgba(0, 0, 0, 0.15);
}

.contact-us .form-content form .inputs .input textarea::placeholder{
    color: #000;
}


.contact-us .form-content form .inputs .input input::placeholder{
    color: #000;
}


.contact-us .form-content form button{
    width: 300px;
    height: 50px;
    background-color: #00a5da;
    border: none;
    outline: none;
    color: #fff;
    transition: 0.5s;
}


@media(max-width:950px){
    .contact-us .contact-info .info {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        margin-bottom: 31px;
    }

    .contact-us .form-content form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 50px;
    }

    .contact-us .form-content form .inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 20px;
    }

    .contact-us.become-dist{
        margin: 0;
    }

    .contact-us.become-dist{
        margin: 0;
    }

    .contact-us.become-dist .form-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 500px;
        padding: 20px;
        padding-top: 0;
        padding-bottom: 50px;
        margin-top: 0;
    }
}

.contact-us.become-dist{
    margin: 0;
}

.contact-us.become-dist{
    margin: 0;
}

.contact-us.become-dist .form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    padding: 20px;
    padding-top: 0;
    padding-bottom: 50px;
    margin-top: 0;
}

.page-header .page-header-content.special{
    margin-bottom: 0;
}

.contact-us.become-dist .form-content form {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 50px;
    padding-top: 60px;
}