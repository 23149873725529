

.prodcut .product-img img{
    width: 100%;
    height: 350px;
    object-fit: contain;
    margin-bottom: 20px;
}

.prodcut .product-img .small-imgs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
}

.prodcut .product-img .small-imgs img{
    width: calc(25% - 20px);
    height: 102px;
    object-fit: contain;
    padding: 10px;
    background-color: #eee;
    cursor: pointer;
}

.prodcut .product-desc{
    padding-left: 30px;
}

.prodcut .product-desc h1{
    color: #000;
    font-size: 27px;
    font-weight: 900;
    width: 90%;
    margin-bottom: 44px;
}

.prodcut .product-desc h5{
    font-size: 15px;
    color: var(--sec-color);
}

.prodcut .product-desc p{
    font-size: 16px;
    width: 95%;
    color: #000000;
    width: 75%;
    margin-bottom: 30px;
}

.prodcut .product-desc a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 48px;
    background-color: var(--sec-color);
    color: #fff;
    margin-top: 60px;
    margin-bottom: 100px;
}

.prodcut .product-desc ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 23px;
    padding: 17px;
    background-color: #f9f9f9;
    box-shadow:0px 0px 6px rgba(0, 0, 0, 0.15);
}

.prodcut .product-desc ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7px;
    color: #000;
    font-weight: 600;
}

.prodcut .product-desc ul li i{
    color: var(--sec-color);
}

.prodcut .product-desc button{
    width: 100%;
    height: 55px;
    margin-top: 17px;
    margin-bottom: 18px;
    color: #fff;
    background-color: var(--sec-color);
    border: none;
    outline: none;

}
