.services{
    position: relative;
    min-height: 600px;
    margin-top: 100px;
    margin-bottom: 100px;
}

.services>img{
    position: absolute;
    z-index: -1;
    opacity: 0.2;
    width: 100%;
    inset: 0;
    height: 100%;
}



.services .services-content .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
}

.services .services-content .header h2{
    font-size: 15px;
    color: var(--sec-color);
}

.services .services-content .header h1{
    color: #000;
    font-size: 27px;
    font-weight: 900;
    width: 90%;
    margin-bottom: 44px;
    text-align: center;
}

.services .services-content .header h1 span{
    color: var(--sec-color);

}


.services .services-content .service{
    width: 450px;
    margin-right: 30px;
    height: 519px;
    margin-top: 50px;
    margin-bottom: 103px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.22);
    padding: 18px;
    background-color: #fff;
}


.services .services-content .service .top{
    height: 60%;
}


.services .services-content .service .top i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background-color: #f1f1f1;
    border-radius: 50%;
    font-size: 37px;
    color: var(--sec-color);
    margin-bottom: 18px;
}

.services .services-content .service .top h1{
    font-size: 22px;
    font-weight: 700;
    color: #000;
}

.services .services-content .service .top p{
    font-size: 15px;
    color: #6a6a6a;
}

.services .services-content .service .bottom{
    height: 50%;
    position: relative;
}


.services .services-content .service .bottom a{
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 145px;
    height: 35px;
    background-color: var(--sec-color);
    color: #fff;
    left: 25%;
    transform: translateX(-50%);
}

.services .services-content .service .bottom div{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #000000a1;
}

.services .services-content .service .bottom img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.services .services-content .swiper-slide{
    width: fit-content !important; 
}


.services .services-content  .swiper-pagination-bullet{
    background-color: var(--sec-color);
    border: 3px solid #fff;
    outline: 1px solid var(--sec-color);
    width: 12px;
    height: 12px;
}