
.our-mission .our-mission-img{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.our-mission .our-mission-img img:nth-child(1){
    width: 69%;
    height: 603px;
    object-fit: cover;
}

.our-mission .our-mission-img img:nth-child(2){
    width: 200px;
    height: 200px;
    background-color: #fff;
    padding: 15px;
    position: absolute;
    border: 1px solid #a8a8a8;
    right: 0;
    top: 50%;
    transform: translateY(-50%);    
    object-fit: cover;
}

.our-mission .our-mission-text h2{
    font-size: 15px;
    color: var(--sec-color);
}

.our-mission .our-mission-text h1{
    color: #000;
    font-size: 27px;
    font-weight: 900;
    width: 90%;
    margin-bottom: 44px;
}

.our-mission .our-mission-text h1 span{
    color: var(--sec-color);

}

.our-mission .our-mission-text p{
    font-size: 16px;
    width: 95%;
    color: #000000;
}

.our-mission .our-mission-text ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.our-mission .our-mission-text ul li{
    width: 50%;
    margin-bottom: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    color: #000;
}

.our-mission .our-mission-text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background-color: var(--sec-color);
    color: #fff;
    margin-top: 50px;
}


@media(max-width:950px){
    .our-mission .our-mission-img img:nth-child(1) {
        width: 81%;
        height: 398px;
        object-fit: cover;
        margin-bottom: 58px;
    }

    .our-mission .our-mission-text h1 {
        font-size: 25px;
        font-weight: 600;
        color: #000;
        margin-bottom: 35px;
    }

    .our-mission .our-mission-text ul li {
        width: 50%;
        margin-bottom: 21px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 7px;
        color: #000;
    }

}