
.page-header .page-header-content {
    min-height: 562px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-bottom: 119px;
}


.page-header .page-header-content h1{
    z-index: 1;
    position: relative;
    color: #fff;
    font-size: 52px;
}



.page-header .page-header-content .navs{
    z-index: 2;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
}

.page-header .page-header-content .navs a{
    color: #fff;
    font-size: 17px;
    font-weight: 500;
}


.page-header .page-header-content .navs a.active{
    color: var(--sec-color);
    font-weight: 600;
}


@media(max-width:950px){
    .page-header .page-header-content h1 {
        z-index: 1;
        position: relative;
        color: #fff;
        font-size: 38px;
    }

    .page-header .page-header-content h1 {
        z-index: 1;
        position: relative;
        color: #fff;
        font-size: 35px;
    }
}