
.explore-projects{
    margin-top: 120px;
    margin-bottom: 220px;
}

.explore-projects .header h1{
    text-align: center;
    margin-top: 50px;
    margin-bottom: 55px;
    color: #000;
    font-size: 27px;
    font-weight: 900;
}

.explore-projects .header h1 span{
    color: var(--sec-color);
}



.explore-projects .project-img {
    position: relative;
    margin-bottom: 25px;
}

.explore-projects .project-img img{
    width: 100%;
    height: 350px;
    object-fit: cover;
    box-shadow:0px 0px 15px rgba(0, 0, 0, 0.22);

}

.explore-projects .project-img h3{
    position: absolute;
    bottom: 0;
    left: 7px;
    background-color: #ffffffb5;
    width: 70%;
    padding: 14px;
    color: #000;
    font-weight: 400;
    font-size: 20px;
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
}