.our-vision{
    margin-top: 100px;
    margin-bottom: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #f9f9f9;
}

.our-vision .vision-img img{
    width: 100%;
    height: 541px;
    object-fit: cover;
}


.our-vision .vision-text h2{
    font-size: 15px;
    color: var(--sec-color);
}


.our-vision .vision-text h1 {
    color: #000;
    font-size: 27px;
    font-weight: 900;
    width: 90%;
    margin-bottom: 44px;
    text-align: left;
}

.our-vision .vision-text h1 span{
    color: var(--sec-color);
}

.our-vision .vision-text p{
    font-size: 15px;
    color: #000;
    margin-bottom: 60px;
}


.our-vision .vision-text .main-points{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.our-vision .vision-text .main-points div{
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 135px;
    margin-bottom: 15px;
    background-color: #fff;
    box-shadow:
    0px 0px 6px rgba(0, 0, 0, 0.15);}


.our-vision .vision-text .main-points div i{
    font-size: 58px;
    color: #000;
    margin-bottom: 22px;
}

.our-vision .vision-text .main-points div h3{
    font-size: 14px;
    width: 71%;
    color: #000;
}


@media(max-width:950px){
    
    .our-vision .vision-img img {
        width: 100%;
        height: 409px;
        object-fit: cover;
        margin-bottom: 22px;
    }
    .our-vision .vision-text h1 {
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 35px;
    }

    .our-vision .vision-text p {
        font-size: 15px;
        margin-bottom: 60px;
    }

    .our-vision .vision-text .main-points div {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: 135px;
        margin-bottom: 15px;
    }
}