.numbers{
    padding-top: 104px;
    padding-bottom: 104px;
    margin-top: 90px;
    margin-bottom: 90px;
    background-color: #171717;
}



.numbers .numbers-content .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #fff;
    margin-bottom: 35px;
}

.numbers .numbers-content .header .left{
    width: 51%;
}


.numbers .numbers-content .header .left h2{
    font-size: 17px;
    color: #39b54a;
}

.numbers .numbers-content .header .left h1{
    font-size: 32px;
    font-weight: 500;
    margin-top: 30px;
    line-height: 50px;
}


.numbers .numbers-content .header .right a{
    background-color: #ffffff0d;
    width: 251px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    height: 45px;
    color: #fff;
}

.numbers .numbers-content .header .right a i{
    background-color: #39b54a;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.numbers .numbers-content .numbers-content-2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 57px;
}

.numbers .numbers-content .numbers-content-2 .number{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #ffffff61;
    width: 25%;
    min-height: 200px;
    gap: 30px;
}

.numbers .numbers-content .numbers-content-2 .number i{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 90px;
    height: 90px;
    font-size: 40px;
    background-color: #ffffff14;
    border-radius: 50%;
}


.numbers .numbers-content .numbers-content-2 .number div{
    text-align: left;
}

.numbers .numbers-content .numbers-content-2 .number div h1{
    font-size: 44px;
    color: #fff;
}

.numbers .numbers-content .numbers-content-2 .number div h2{
    font-size: 16px;
    font-weight: 400;
    color: #6f6f6f;
}


@media(max-width:950px){
    .numbers .numbers-content .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: #fff;
        margin-bottom: 35px;
    }

    .numbers .numbers-content .header .left {
        width: 100%;
    }

.numbers .numbers-content .header .left h1 {
    font-size: 23px;
    font-weight: 500;
    margin-top: 30px;
    line-height: 32px;
}

.numbers .numbers-content .header .right a {
    background-color: #ffffff0d;
    width: 251px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    height: 45px;
    color: #fff;
    margin-top: 50px;
}

.numbers .numbers-content .numbers-content-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 57px;
}

.numbers .numbers-content .numbers-content-2 .number {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #ffffff61;
    width: 100%;
    min-height: 200px;
    gap: 30px;
}
}