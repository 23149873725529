
.landing .landing-content {
    position: relative;
    min-height: 674px;
    overflow: hidden;
}

.landing .landing-content video{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.landing .landing-content .overlay{
    inset: 0;
    background-color: #000000a3;
    position: absolute;
}

.landing .landing-content .text {
    position: relative;
    z-index: 2;
    padding: 42px;
    min-height: 670px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.landing .landing-content .text h2{
    font-size: 23px;
    color: var(--sec-color);
    font-weight: 400;
    margin-bottom: 50px;
}

.landing .landing-content .text h1{
    font-size: 51px;
    font-weight: 500;
    color: #fff;
    width: 50%;
    font-weight: 600;
}

.landing .landing-content .text h1 span{
    color: var(--sec-color);

}

.landing .landing-content .text h3 {
    font-size: 23px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 50px;
}


.landing .landing-content .text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    width: 200px;
    height: 45px;
    margin-top: 20px;
    color: #000000;
    font-size: 14px;
    gap: 14px;
    padding: 9px;
    font-weight: 700;
}

.landing .landing-content .text a i{
    width: 35px;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #00a5da;
    color: #fff;

}



.landing .landing-content .text-overlay-1 {
    background-color: #00a5da9e;
    width: 41%;
    height: 161%;
    transform: rotate(20deg);
    top: -69px;
    right: -94px;
    position: absolute;
    direction: rtl;
}

.landing .landing-content .text-overlay-2 {
    background-color: #ffffff26;
    width: 41%;
    height: 161%;
    transform: rotate(20deg);
    top: -155px;
    right: 142px;
    position: absolute;
    direction: rtl;
}


@media(max-width:950px){
    .landing .landing-content .text h1 {
        font-size: 38px;
        font-weight: 500;
        color: #fff;
        width: 100%;
        font-weight: 600;
    }
}