.questions{
margin-top: 80px;
margin-bottom: 80px;
}

.questions .questions-image{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.questions .questions-image .top{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    width: 85%;
}

.questions .questions-image .top img:nth-child(1){
    width: 58%;
    height: 251px;
    object-fit: cover;
}

.questions .questions-image .top img:nth-child(2){
    width: 40%;
    height: 251px;
    object-fit: cover;
}

.questions .questions-image .bottom{
    width: 85%;
}


.questions .questions-image .bottom img{
    width: 100%;
    height: 445px;
    object-fit: cover;
    margin-top: 15px;
    object-position: center;
}





.questions .questions-image .logo img{
    position: absolute;
    top: 34%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 153px;
    background-color: #171717;
    height: 150px;
    border-radius: 50%;
    object-fit: contain;
}



.questions .questions-text h2{
    font-size: 15px;
    color: var(--sec-color);
}

.questions .questions-text h1{
    color: #000;
    font-size: 27px;
    font-weight: 900;
    width: 90%;
    margin-bottom: 44px;
    text-align: left;
}

.questions .questions-text h1 span{
    color: var(--sec-color);

}
.questions .questions-text .question{
    margin-bottom: 40px;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    padding: 18px;
    height: 54px;
    transition: 0.5s;
    overflow: hidden;
    cursor: pointer;
}

.questions .questions-text .question.active{
    height: fit-content;
}

.questions .questions-text .question h3{
    font-size: 16px;
    color: #5e5e5e;
    margin-bottom: 21px;
    margin: 0;
    font-weight: 400;
    font-weight: 700;
}

.questions .questions-text .question p{
    margin: 0;
    transition: 0.3s;
    height: 100%;
    overflow: hidden;
    font-size: 14px;
    margin-top: 20px;
    color: #656565;
}


.questions .questions-text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 207px;
    border: 1px solid var(--sec-color);
    color: #000;
    height: 44px;
    padding: 5px;
}

.questions .questions-text a i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: var(--sec-color);
    color: #fff;
}


@media(max-width:950px){
    .questions .questions-text {
        margin-top: 60px;
    }

    .questions .questions-text h1 {
        font-size: 28px;
        font-weight: 500;
        color: #000;
        width: 100%;
        text-align: left;
        margin-bottom: 51px;
    }

    .questions .questions-text .question {
        margin-bottom: 46px;
        background-color: #fff;
        border: 1px solid #e1e1e1;
        padding: 18px;
        height: 70px;
        transition: 0.5s;
        overflow: hidden;
        cursor: pointer;
    }

    .questions .questions-text .question h3 {
        font-size: 16px;
        color: #5e5e5e;
        margin-bottom: 21px;
        margin: 0;
        font-weight: 400;
        font-weight: 700;
        margin-bottom: 38px;
    }
}